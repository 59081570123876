<script setup lang="ts">
    import Task from '~~/app/task/models/Task';
    import EndpointFactory from '~~/app/factories/EndpointFactory';
    import { ModelType } from '~~/app/base/schemas/BaseSchema';
    import TaskEndpoint from '~~/app/task/endpoints/TaskEndpoint';
    import TaskSchema from '~~/app/task/schemas/TaskSchema';
    import { storeToRefs } from 'pinia';

    const { authed } = storeToRefs(useAuthStore());
    const props = defineProps({
  task: null
});

    const className = computed(() => {
        if (props.task.isCompleted) {
            return 'border-green-500 bg-green-500 hover:bg-neutral-400 hover:border-gray-400';
        }
        if (props.task.isDue) {
            return 'border-red-500 bg-red-50 text-red-50 hover:bg-neutral-200 hover:border-gray-200';
        }
        return 'border-gray-200 hover:bg-neutral-200';
    });

    const toggleCompletedAt = async () => {
        await EndpointFactory.make(ModelType.TASKS).toggleCompletedAt(props.task);
    };
</script>

<template>
    <div
        class="cursor-pointer h-[20px] w-[20px] @sm:h-[24px] @sm:w-[24px] rounded-full flex items-center justify-center flex-shrink-0 border-2 transition-all"
        :class="className"
        @click.prevent.stop="toggleCompletedAt()"
    >
        <i class="text-xs @sm:text-sm text-white fa-regular fa-check" />
    </div>
</template>
